









































































import {defineComponent, PropType} from '@vue/composition-api';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import Gate from '@/permissions/Gate';
import {PolicyMap} from '@/permissions/types/PolicyMap';
import {VersionTaskState} from '@/tasks/types/VersionTaskState';

export default defineComponent({
  name: 'TorontoCHMB41HQuiz2Question1',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  props: {
    taskState: {
      type: Object as PropType<VersionTaskState>,
      required: true,
    },
  },
  data() {
    return {
      explanations: {
        A: '1,3 diaxial strain is maximized because the number of substituents in axial positions is minimized.',
        B: '1,3-diaxial strain is minimized because the number of substituents in equatorial positions is minimized.',
        C: 'Maximizes the potential for hydrogen bonding between the substituents.',
        D: '1,3-diaxial strain is minimized because the number of substituents in axial positions is minimized.',
        E: '1,3-diaxial strain is maximized because the number of substituents in equatorial positions is minimized.',
      },
      inputs: {
        chairStructures: [] as string[],
        mostStable: null as string | null,
        explanation: null as string | null,
      },
    };
  },
  computed: {
    seed(): number {
      return (this.$gate as Gate<PolicyMap>).user?.id ?? 1;
    },
    version(): number {
      return this.taskState.getValueByName('version')?.content?.value ?? 1;
    },
    imagePrefix(): string {
      return `/img/assignments/UofT/CHMB41H/2023/Quiz2/Question1/q2q1v${this.version}-`;
    },
    chairChoices(): string[] {
      return seededShuffle(['A', 'B', 'C', 'D', 'E', 'F'], this.seed);
    },
    explanationChoices(): string[] {
      return seededShuffle([...Object.keys(this.explanations)], this.seed);
    },
    moleculeImage(): string {
      return this.imagePrefix + 'molecule.png';
    },
    chairImages(): string[] {
      return this.chairChoices.map((l: string) => this.imagePrefix + 'chair' + l + '.png');
    },
  },
  watch: {
    'inputs.chairStructures'(value: string[], oldValue: string[]) {
      if (oldValue.length > value.length) {
        const diff = oldValue.filter((v) => !value.includes(v));
        if (diff.includes(this.inputs.mostStable as string)) {
          this.inputs.mostStable = null;
        }
      }
    },
    'inputs.mostStable'(value: string) {
      if (value) {
        if (!this.inputs.chairStructures.includes(value)) {
          this.inputs.chairStructures.push(value);
        }
      }
    },
  },
});
